<template>
  <div>
    <div class="mb-5">
      Chọn khách hàng, sàn, shop. Sau đó xác nhận để đồng bộ lại đơn hàng lỗi.
      <br />
      Lưu ý: Chỉ đồng bộ đơn hàng ở trạng thái chưa xác nhận đơn trong 3 ngày
      gần nhất. Với những đơn đã xác nhận trên sàn, để tránh trùng lặp khi
      chuyển đổi hệ thống, vui lòng xuất excel sàn và xử lí bằng chức năng "Tạo
      đơn sàn"
    </div>
    <v-row>
      <v-col cols="3">
        <SelectCustomer
          :label="$t('labels.customer')"
          :placeholder="$t('labels.customer')"
          :filters="{ is_active: true }"
          name="id_customer"
          sort-name="id_customer"
          @onFilter="onCustomerChange"
        />
      </v-col>
      <v-col cols="3">
        <select-multiple
          v-model="id_e_markets"
          :options="eMarkets"
          :label="$t('labels.e_market')"
        ></select-multiple>
      </v-col>
      <v-col cols="3">
        <select-multiple
          v-model="id_shops"
          :options="shopOptions"
          :label="$t('labels.shop')"
        ></select-multiple>
      </v-col>
      <v-col cols="3">
        <v-btn
          color="primary"
          block
          style="height: 40px"
          @click="confirmSync"
          >{{ $t("labels.confirm") }}</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { httpClient } from "@/libs/http";

export default {
  name: "ReSyncOrder",
  components: {
    SelectCustomer: () => import("@/components/table/SelectCustomer"),
  },
  data: () => ({
    eMarkets: [
      {
        text: "Shopee",
        value: 1,
      },
      {
        text: "Lazada",
        value: 2,
      },
      {
        text: "Tiktok",
        value: 5,
      },
    ],
    customers: [],
    shops: [],
    id_customer: null,
    id_e_markets: [],
    id_shops: [],
    isLoading: false,
  }),
  computed: {
    shopOptions() {
      if (!this.id_customer || this.id_e_markets.length === 0) {
        return [];
      }
      return [...this.shops]
        .filter((shop) => this.id_e_markets.includes(shop.id_e_marketplace))
        .map((e) => ({
          value: e.id,
          text: e.display_name,
        }));
    },
  },
  mounted() {
    // this.getCustomer()
  },
  methods: {
    async getCustomer() {
      const { data } = await httpClient.post(
        "/customer/v1/get-all-has-contract",
        { is_active: true }
      );
      this.customers = [...data].map((e) => ({
        value: e.id,
        text: e.company_name || "",
      }));
    },
    async getShop() {
      const { data } = await httpClient.post(
        "/customer/v1/get-all-shop-by-customer",
        {
          id_customer: this.id_customer,
          active: 1,
        }
      );
      this.shops = [...data];
    },
    async confirmSync() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t("messages.loading"));
        return false;
      }
      this.isLoading = true;

      try {
        await httpClient.post("/customer/v1/re-sync-order", {
          id_customer: this.id_customer,
          id_e_markets: this.id_e_markets,
          id_shops: this.id_shops,
        });
        this.$vToastify.success("Hệ thống đang đồng bộ. Vui lòng chờ vài phút");
        this.isLoading = false;
      } catch (e) {
        const errMsg =
          (e.response &&
            e.response.data &&
            e.response.data.error &&
            e.response.data.error.message) ||
          null;
        this.$vToastify.error(errMsg);
        this.isLoading = false;
      }
    },
    onCustomerChange(filter) {
      this.id_customer = filter.value;
      this.getShop();
    },
  },
};
</script>
